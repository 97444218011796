// 例如 next-i18next.config.js
const path = require("path");

module.exports = {
  i18n: {
    defaultLocale: "en",
    locales: ["en", "zh", "tw"],
    defaultNS: "common",
    localeDetection: false,
  },
  localesPath: path.resolve("./public/locales"),
};
