import React, { useEffect, useState } from "react";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { Router, useRouter } from "next/router";
import NProgress from "nprogress";
import "@/styles/mixins.less";
import "@/styles/fonts.css";
import "@/styles/globals.less";
import "nprogress/nprogress.css";
import { App as AntdApp } from "antd";
import ConfigProvider, { useConfigProvider } from "@/context/ConfigProvider";
import ShoppingCartProvider from "@/context/UserProvider";
import withTheme from "@/theme";
import "@/lib/hooks/common/firebaseConfig/init";
import { appWithTranslation } from "next-i18next";
import { Locale } from "antd/lib/locale";
import { CurrentLanguage } from "@/shared/language";
// import Login from '@/components/Login';
const Login = dynamic(() => import("@/components/Login"), { ssr: false });
import UserProvider from "@/context/UserProvider";
// import CookieConsentContent from '@/components/Common/CookieConsent';
import dynamic from "next/dynamic";
// import CustomHead from '@/components/Common/CustomHead';
import LoginResult from "@/components/LoginResult";
// import ArticleBottomAlert from '@/components/Common/ArticleBottomAlert';
import { AppSettings } from "@/shared/app-common";
import Head from "next/head";

Router.events.on("routeChangeStart", NProgress.start);
Router.events.on("routeChangeError", NProgress.done);
Router.events.on("routeChangeComplete", NProgress.done);

require("../styles/ins-theme.less");

const CookieConsentContent = dynamic(
  () => import("@/components/Common/CookieConsent"),
  {
    ssr: false,
  }
);
const ArticleBottomAlert = dynamic(
  () => import("@/components/Common/ArticleBottomAlert"),
  {
    ssr: false,
  }
);

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<P = {}> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const [loc, setLoc] = useState<Locale>();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();

  useEffect(() => {
    setLoc(CurrentLanguage(router.locale).locale);
  }, [router.locale]);

  // 判断是否溯源
  useEffect(() => {
    const source = localStorage.getItem("source");
    if (!source && router.query.source) {
      localStorage.setItem("source", router.query.source as string);
    }
    // 在这里执行你希望在每个页面刚进来时执行的代码
  }, [router.isReady]);
  const jsonData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "Erasa",
    image: `${AppSettings.logoUrl}`,
    url: "https:",
    applicationCategory:
      "Erasa｜Remove Leaked Content Now!  Protect Your Content",
  };

  const jsonListData = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "https://www.erasa.ai",
          name: "Home",
        },
      },
      {
        "@type": "ListItem",
        position: 2,
        item: {
          "@id": "https://www.erasa.ai/dmca-protection-badge",
          name: "DMCA",
        },
      },
      {
        "@type": "ListItem",
        position: 3,
        item: {
          "@id": "https://www.erasa.ai/plan",
          name: "PLAN",
        },
      },
      {
        "@type": "ListItem",
        position: 4,
        item: {
          "@id": "https://www.erasa.ai/onlyfans-restricted-words-checker",
          name: "Onlyfans-Restricted-Words-Checker",
        },
      },
      {
        "@type": "ListItem",
        position: 5,
        item: {
          "@id": "https://www.erasa.ai/onlyfans-caption-generator",
          name: "Onlyfans-Caption-Generator",
        },
      },
      {
        "@type": "ListItem",
        position: 6,
        item: {
          "@id": "https://www.erasa.ai/blog",
          name: "Blog",
        },
      },
    ],
  };
  return withTheme(
    <AntdApp>
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${AppSettings.clarityId}");`,
        }}
      />
      <script
        id="json-data-id"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(jsonData),
        }}
      />
      <script
        id="json-list-data-id"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(jsonListData),
        }}
      />
      <ConfigProvider>
        <UserProvider>
          <ShoppingCartProvider>
            {/* <ErrorBoundary> */}

            {/* <CustomHead /> */}
            {getLayout(<Component {...pageProps} />)}
            <Login />
            <LoginResult />
            {/* <ArticleBottomAlert /> */}
            <CookieConsentContent />

            {/* </ErrorBoundary> */}
          </ShoppingCartProvider>
        </UserProvider>
      </ConfigProvider>
    </AntdApp>,
    loc
  );
}

export default appWithTranslation(App);
