import enUS from "antd/lib/locale/en_US";
import zhCN from "antd/lib/locale/zh_CN";
import zhTW from "antd/lib/locale/zh_TW"; // 新增这行

interface AppLanguage {
  defaultLocale: string;
  locales: string[];
}

export const CurrentLanguage = (locale: string | undefined) => {
  if (locale == undefined || locale.length == 0) {
    return {
      code: "en",
      name: "English",
      locale: enUS,
    };
  }
  const item = AllSupportLanguages.filter((item) => {
    return item.code == locale;
  });
  if (item.length > 0) {
    return item[0];
  }
  return {
    code: "en",
    name: "English",
    locale: enUS,
  };
};

export const AllSupportLanguages = [
  {
    name: "English",
    code: "en",
    locale: enUS,
  },
  {
    name: "简体中文",
    code: "zh",
    locale: zhCN,
  },
  {
    name: "繁體中文",
    code: "tw",
    locale: zhTW,
  },
];

export const AppLanguage = {
  defaultLocale: "en",
  locales: AllSupportLanguages.map((item) => {
    return item.code;
  }),
};
