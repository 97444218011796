import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { App } from "antd";
import {
  getAuth,
  signOut,
  deleteUser,
  getRedirectResult,
  GoogleAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import { InsMonitorModel } from "@/types/home";
import { useTranslation } from "next-i18next";
import {
  loginRedirect,
  userUpdate,
  getUserInfoApi,
  getPersionalProductApi,
  getUserUseTimesApi,
} from "@/lib/service";
import { ga_logEvent } from "@/shared/ga";
import { EventName } from "@/shared/event-types";

type UserProviderType = {
  /**
   * total cart quantity
   */
  // total?: number;
  openLogin?: boolean;
  isLoginView?: boolean;
  openLoginResult?: boolean;
  accountList?: InsMonitorModel[];
  /**
   * add cart callback function
   * @param params Product information added
   */
  onAddShop?: <P = any>(params: P) => Promise<{ data: P }>;
  onDeleteShopCarItem?: <P = any>(params: P) => Promise<{ data: P }>;
  onOpenLoginModal?: (open: boolean) => void;
  onChangedLoginView?: (isLoginView: boolean) => void;
  onChangedLoginResult?: (isLoginView: boolean) => void;
  onAddToken?: (
    token?: string,
    signInMethod?: string,
    idToken?: string
  ) => void;
  onInitAccountList?: (accountList?: InsMonitorModel[]) => void;
  onAddAccount?: (account?: InsMonitorModel) => void;
  onSignOut?: (showAlert?: boolean) => void;
  onDeleteUser?: () => void;

  /**
   * Refresh cart data
   */
  onRefreshCartData?: () => Promise<void>;

  isLogin?: boolean;
  userInfo?: any;
  setUserInfo?: any;
  userSub?: any;
  UpDateUserSub?: any;
  userUse?: any;
};

const Context = createContext<UserProviderType>({});

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const { message } = App.useApp();

  const router = useRouter();
  // const [totalCartQuantity, setTotalCartQuantity] = useState<number>();
  const [openLogin, setOpenLogin] = useState<boolean>(false);
  const [isLoginView, setIsLoginView] = useState<boolean>(true);
  const [openLoginResult, setOpenLoginResult] = useState<boolean>(false);
  const [accountList, setAccountList] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [userSub, setUserSub] = useState<any>({});

  const token = Cookies.get("userToken");
  const [isLogin, setIsLogin] = useState<boolean>(
    token != null && token != undefined && token.length > 0
  );

  useEffect(() => {
    if (isLogin) {
      getUserInfoApi().then((res) => {
        setUserInfo(res.data);
        localStorage.setItem("uif", JSON.stringify(res.data));
      });
    }
  }, [isLogin]);

  useEffect(() => {
    if (isLogin && !userSub?.item_id) {
      getPersionalProductApi().then((res) => {
        if (res.data?.length > 0) {
          setUserSub(res.data[0]);
        }
      });
    }
  }, []);
  const UpDateUserSub = () => {
    getPersionalProductApi().then((res) => {
      setUserSub(res.data[0]);
    });
  };
  // const token1 = ls_getItem('userToken');
  // const [isLogin, setIsLogin] = useState<boolean>((token != null && token != undefined && token.length > 0) || (token1 != null && token1 != undefined && token1.length > 0));

  const handleAddShopCarItem = async (params: any) => {
    return Promise.resolve(params);
  };

  useEffect(() => {
    // The initialization is to get the total number of shopping carts
    const token = Cookies.get("userToken");
    setIsLogin(token != null && token != undefined && token.length > 0);
    // const token1 = ls_getItem('userToken');
    // setIsLogin((token != null && token != undefined && token.length > 0) || (token1 != null && token1 != undefined && token1.length > 0));
    // 用户信息
    let uif = localStorage.getItem("uif");
    if (uif != "undefined") {
      let tep_userInfo = JSON.parse(uif);
      setUserInfo(tep_userInfo);
    } else {
      getUserInfoApi().then((res) => {
        setUserInfo(res.data);
        localStorage.setItem("uif", JSON.stringify(res.data));
      });
    }
  }, []);

  useEffect(() => {
    if (typeof window !== undefined) {
      // window.location.reload()
      let timer = setTimeout(() => {
        loadUserData();
        clearTimeout(timer);
      }, 100);
    }
  }, []);

  const getToken = (id: string, signInMethod: string) => {
    const pathUrl =
      typeof window !== undefined
        ? window.location.href
        : process.env.NEXT_APP_WEB_HOST;
    // loginRedirect(id, pathUrl, router.locale).then((res) => {
    //   if (res?.code == 0) {
    //     // onAddToken(res?.data?.token, signInMethod, id);
    //     // // onOpenLoginModal(false);
    //     // window.location.reload()
    //   } else {
    //     message.error(res.message);
    //   }
    // });

    userUpdate(id, router.locale).then(
      (res: { data: any; code?: number; message?: string }) => {
        if (res?.data?.first_login) {
          if (res.code == 0) {
            ga_logEvent(EventName.user_register_Success);
          } else {
            ga_logEvent(EventName.user_register_Fail);
          }
        }

        if (res?.code == 0) {
          // window.location.reload()
          // onAddToken(res?.data?.token, signInMethod, id);
          // onOpenLoginModal(false);
          localStorage.removeItem("source");
          router.replace(
            `/login-success?token=${res?.data?.token}&url=${pathUrl}`
          );
          ga_logEvent(EventName.user_Login_Success);
        } else {
          message.error(res.message);
        }
      }
    );
  };

  const loadUserData = () => {
    const auth = getAuth();
    getRedirectResult(auth).then(async (result) => {
      if (result) {
        const credential = GoogleAuthProvider.credentialFromResult(result);

        const token = credential.accessToken;
        Cookies.set("accessToken", token, { expires: 7 });
        // ls_saveItem('accessToken', token)
        result.user.getIdToken().then((id) => {
          getToken(id, "google");
          ga_logEvent(EventName.login, { method: "Google" });
        });
      }
    });
  };

  const onOpenLoginModal = (open: boolean) => {
    setOpenLogin(open);
  };

  const onChangedLoginView = (open: boolean) => {
    setIsLoginView(open);
  };

  const onChangedLoginResult = (open: boolean) => {
    UpDateUserSub();
    setOpenLoginResult(open);
  };

  const handleRefreshCartData = async () => {
    return new Promise<void>((resolve) => {
      message.success("Refresh successfully", 1.2, () => resolve(null));
    });
  };

  const onAddToken = (
    token?: string,
    signInMethod?: string,
    idToken?: string
  ) => {
    if (token != undefined) {
      Cookies.set("userToken", token, { expires: 7 });
      Cookies.set("SignInMethod", signInMethod, { expires: 7 });
      Cookies.set("IdToken", idToken, { expires: 7 });
    } else {
      Cookies.remove("userToken");
      Cookies.remove("SignInMethod");
      Cookies.remove("IdToken");
    }
    setIsLogin(token != undefined);
  };

  const onAddAccount = (account?: InsMonitorModel) => {
    if (account != undefined) {
      setAccountList([...accountList, account]);
    }
  };

  const onInitAccountList = (accountList?: InsMonitorModel[]) => {
    setAccountList([...(accountList ?? [])]);
  };
  const clearAllCookies = () => {
    const cookies = document.cookie.split(";");
    // 遍历并删除每个cookie
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      Cookies.remove(name);
    }
  };

  const onSignOut = (showAlert?: boolean) => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        clearAllCookies();
        setUserInfo({});
        if (showAlert) {
          message.success(t("SignOutSuccessful"));
        }
        onAddToken(undefined);
        setUserSub({});
      })
      .catch((error) => {
        message.error(t("Log_Out_Failed_Error_Msg"));
      });
  };

  const onDeleteUser = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    deleteUser(user)
      .then(() => {
        message.success(t("UserDeleted"));
        onAddToken(undefined);
        localStorage.clear();
        setAccountList([]);
        router.replace("/");
      })
      .catch((error) => {
        message.error(t("Account_Deletion_Failed_Error_Msg"));
      });
  };
  const [userUse, setuserUse] = useState({});
  useEffect(() => {
    if (isLogin) {
      getUserUseTimesApi().then((res) => {
        setuserUse(res.data);
      });
    }
  }, [isLogin]);

  const UpdateUserUs = () => {
    getUserUseTimesApi().then((res) => {
      setuserUse(res.data);
    });
  };

  const exposed = {
    openLogin,
    isLoginView,
    openLoginResult,
    // total: totalCartQuantity,
    isLogin: Cookies?.get("userToken")?.length > 0,
    accountList,
    onAddShop: handleAddShopCarItem,
    onOpenLoginModal,
    onChangedLoginView,
    onChangedLoginResult,
    onRefreshCartData: handleRefreshCartData,
    onAddToken,
    onInitAccountList,
    onAddAccount,
    onSignOut,
    onDeleteUser,
    userInfo,
    setUserInfo,
    userSub,
    UpDateUserSub,
    userUse,
    UpdateUserUs,
  };

  return <Context.Provider value={exposed}>{children}</Context.Provider>;
};

export const useUserProvider = () => useContext(Context);

export default UserProvider;
